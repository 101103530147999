"use client"

import { useState } from "react"
import { BsDashCircle, BsPlusCircle, BsTrash3 } from "react-icons/bs"

import classNames from "@src/utilities/class-names"
import { formatCurrency } from "@src/utilities/format-numbers"

import type { CartLineItem } from "@src/entities/types/api"

export interface CartFlyoutLineItemProps {
  item: Partial<CartLineItem>
  isUpdating: boolean
  onChangeItemQuantity: (sku: string, quantity: number) => void
  onRemoveItem: (sku: string) => void
}

export default function CartFlyoutLineItem({
  isUpdating,
  item,
  onChangeItemQuantity,
  onRemoveItem,
}: CartFlyoutLineItemProps) {
  const [qtyInput, setQtyInput] = useState<number>(item.quantity ?? 0)

  return (
    <div
      className={classNames(
        "flex space-x-2",
        isUpdating ? "opacity-50 pointer-events-none" : ""
      )}
      data-component="cart-flyout-line-item"
    >
      <div
        className="bg-cover bg-center bg-repeat-none border border-slate-200 w-16 h-16"
        style={{
          backgroundImage: `url(${item.product?.thumbnailUrl ?? ""})`,
        }}
      />
      <div className="space-y-2">
        <div className="flex justify-between grow space-x-2">
          <div className="space-y-0">
            <div className="font-bold">{item.product?.name ?? ""}</div>
            <div className="text-gray-500 text-xs">
              {item.product?.description ?? ""}
            </div>
          </div>
          <div className="font-bold">
            {formatCurrency(item.priceInCart ?? 0)}
          </div>
        </div>
        <div className="flex justify-between grow space-x-2">
          <div className="flex items-center space-x">
            <button
              className="p-2 text-black"
              onClick={() => {
                onChangeItemQuantity(
                  item.product?.sku ?? "",
                  item.quantity ? item.quantity - 1 : 0
                )
              }}
            >
              <BsDashCircle className="w-6 h-6" />
            </button>
            <input
              className="border border-black rounded inline-flex grow-0 py-1 px-0 text-center w-12"
              type="number"
              onBlur={() =>
                onChangeItemQuantity(item.product?.sku ?? "", qtyInput)
              }
              onChange={(e) => setQtyInput(parseInt(e.target.value, 10))}
              value={qtyInput}
              step={1}
            />
            <button
              className="p-2 text-black"
              onClick={() => {
                onChangeItemQuantity(
                  item.product?.sku ?? "",
                  item.quantity ? item.quantity + 1 : 1
                )
              }}
            >
              <BsPlusCircle className="w-6 h-6" />
            </button>
          </div>
          <button className="text-slate-500">
            <BsTrash3
              className="w-6 h-6"
              onClick={() => {
                onRemoveItem(item.product?.sku ?? "")
              }}
            />
          </button>
        </div>
      </div>
    </div>
  )
}
