import { type MouseEventHandler } from "react"
import { BsBag, BsBagFill, BsList } from "react-icons/bs"

import { useAppSelector } from "@src/hooks/use-redux"

import { getCartCount } from "@src/redux/cart.slice"

import Logo from "@src/components/Logo"
import Preheader from "@src/components/PreHeader"
import { formatNumberWithSeparators } from "@src/utilities/format-numbers"

export interface HeaderProps {
  isScrolled: boolean
  isWholesalePage: boolean
  onMenuButtonClick: MouseEventHandler<HTMLButtonElement>
  onToggleCartFlyout: MouseEventHandler<HTMLButtonElement>
  preheaderMessage?: string
  preheaderNavigationMenu?: onyx.Types.NavigationMenu
  primaryNavigationMenu?: onyx.Types.NavigationMenu
  secondaryNavigationMenu?: onyx.Types.NavigationMenu
}

export default function Header({
  isScrolled,
  isWholesalePage,
  onMenuButtonClick,
  onToggleCartFlyout,
  preheaderMessage,
  preheaderNavigationMenu,
  primaryNavigationMenu,
  secondaryNavigationMenu,
}: HeaderProps) {
  const cartItemsCount = useAppSelector(getCartCount)

  return (
    <header
      className="bg-white fixed font-sans left-0 right-0 top-0 transition-shadow z-[90]"
      data-scrolled={isScrolled}
      data-component="smart-header"
    >
      <Preheader
        message={preheaderMessage}
        navigationMenu={preheaderNavigationMenu}
      />
      <div className="flex relative items-center justify-between px-4 mx-auto max-w-screen-2xl">
        <div>
          <button
            className="bg-transparent flex items-center justify-start h-16 text-2xl w-16 xl:hidden"
            onClick={onMenuButtonClick}
          >
            <BsList />
          </button>
          <nav className="uppercase font-semibold hidden tracking-wider xl:flex">
            {primaryNavigationMenu &&
              primaryNavigationMenu.items.map((item, idx) => (
                <a
                  className="leading-[5rem] mr-6 relative transition-colors text-black text-sm hover:text-orange hover:[&>span:before]:scale-x-100"
                  href={item.url}
                  key={`primary-nav-menu-${idx}`}
                >
                  <span className="relative before:content-[''] before:block before:bg-orange before:h-1 before:absolute before:mt-1 before:origin-center before:scale-x-0 before:transition-transform before:top-full before:w-full">
                    {item.text}
                  </span>
                  {item.items && (
                    <ol className="hidden">
                      {item.items.map((submenuItem, idxTwo) => (
                        <li key={`primary-nav-submenu-${idx}-${idxTwo}`}>
                          <a href={item.url}>{submenuItem.text}</a>
                        </li>
                      ))}
                    </ol>
                  )}
                </a>
              ))}
          </nav>
        </div>
        <div className="absolute left-1/2 top-1/2 -translate-x-1/2 -translate-y-1/2 w-36">
          <Logo
            additionalClasses={["py-2"]}
            asLink={true}
            linkTitle="Onyx + Rose Home"
            url="/"
          />
        </div>
        <nav className="flex uppercase font-semibold tracking-wider">
          {secondaryNavigationMenu &&
            secondaryNavigationMenu.items.map((item, idx) => (
              <a
                className="hidden leading-[5rem] ml-6 relative transition-colors text-black text-sm hover:text-orange xl:inline-block hover:[&>span:before]:scale-x-100"
                href={item.url}
                key={`secondary-nav-menu-${idx}`}
              >
                <span className="relative before:content-[''] before:block before:bg-orange before:h-1 before:absolute before:mt-1 before:origin-center before:scale-x-0 before:transition-transform before:top-full before:w-full">
                  {item.text}
                </span>
              </a>
            ))}
          {isWholesalePage ? (
            <button
              className="bg-transparent flex items-center justify-end h-16 text-xl relative w-16 xl:leading-[5rem] xl:h-auto xl:w-10 xl:pb-2"
              data-cart-has-items={cartItemsCount > 0}
              data-cart-is-loading={
                typeof cartItemsCount === "undefined" || cartItemsCount === -1
              }
              onClick={onToggleCartFlyout}
            >
              {cartItemsCount > 0 ? <BsBagFill /> : <BsBag />}
              {cartItemsCount > 0 && (
                <span>{formatNumberWithSeparators(cartItemsCount)}</span>
              )}
            </button>
          ) : (
            <a
              className="bg-transparent flex items-center justify-end h-16 text-xl relative w-16 xl:leading-[5rem] xl:h-auto xl:w-10 xl:pb-2"
              data-cart-has-items={cartItemsCount > 0}
              data-cart-is-loading={
                typeof cartItemsCount === "undefined" || cartItemsCount === -1
              }
              href="/cart"
            >
              {cartItemsCount > 0 ? <BsBagFill /> : <BsBag />}
              {cartItemsCount > 0 && (
                <span>{formatNumberWithSeparators(cartItemsCount)}</span>
              )}
            </a>
          )}
        </nav>
      </div>
    </header>
  )
}
