import apolloClient from "@src/utilities/apollo-client"

import { CART_ADD_DISCOUNT_CODE } from "@src/operations/api/cart"

import type {
  AddDiscountCodeMutation,
  AddDiscountCodeMutationVariables,
} from "@src/entities/types/api"

export default async function addDiscountCode(code: string) {
  return apolloClient.mutate<
    AddDiscountCodeMutation,
    AddDiscountCodeMutationVariables
  >({
    mutation: CART_ADD_DISCOUNT_CODE,
    variables: { code },
  })
}
