import { gql } from "@apollo/client"

export const FRAGMENT_FULL_CART = gql`
  fragment CoreFullCartFields on Cart {
    allowDiscountCodes
    discountCode
    discountName
    id
    isWholeSale
    lineItems {
      frequencyInWeeks
      priceInCart
      product {
        categoryId
        categoryName
        description
        name
        isDelta8
        isThca
        priceRetail
        priceOnSale
        priceSubscription
        priceWholesale
        sku
        stock
        thumbnailUrl
      }
      quantity
    }
    totals {
      subtotal
      discounts
      shipping
      taxes
      total
    }
    shippingAddress {
      firstName
      lastName
      email
      phoneNumber
      streetOne
      streetTwo
      city
      state
      zip
      country
    }
  }
`

export const ADD_PRODUCT_TO_CART = gql`
  mutation AddProductToCart($input: CartAddInput!) {
    addProductToCart(input: $input) {
      cartCount
    }
  }
`

export const ADD_PRODUCTS_TO_CART = gql`
  mutation AddProductsToCart($input: [CartAddInput!]!) {
    addProductsToCart(input: $input) {
      cartCount
    }
  }
`

export const CART_GET_FULL_CART = gql`
  ${FRAGMENT_FULL_CART}
  query GetFullCart {
    cart {
      ...CoreFullCartFields
    }
  }
`

export const CART_GET_FULL_CART_FOR_PAYMENT = gql`
  ${FRAGMENT_FULL_CART}
  query GetCartAndSezzleUrl {
    cart {
      ...CoreFullCartFields
    }
    sezzleCheckoutUrl
  }
`

export const CART_ADD_DISCOUNT_CODE = gql`
  ${FRAGMENT_FULL_CART}
  mutation AddDiscountCode($code: String!) {
    addDiscount(code: $code) {
      ...CoreFullCartFields
    }
  }
`

export const CART_REMOVE_DISCOUNT_CODE = gql`
  ${FRAGMENT_FULL_CART}
  mutation RemoveDiscountCode {
    removeDiscount {
      ...CoreFullCartFields
    }
  }
`

export const CART_REMOVE_ITEM = gql`
  ${FRAGMENT_FULL_CART}
  mutation RemoveFromCart($input: CartRemoveItemInput!) {
    cartRemoveItem(input: $input) {
      ...CoreFullCartFields
    }
  }
`

export const CART_TRANSFER = gql`
  mutation TransferCartToNewUid($input: CartTransferInput!) {
    cartTransfer(input: $input) {
      id
    }
  }
`

export const CART_SET_SHIPPING_INFO = gql`
  ${FRAGMENT_FULL_CART}
  mutation SetCartShippingInfo($input: CartShippingInput!) {
    cartSetShipping(input: $input) {
      emailSuggestion
      cart {
        ...CoreFullCartFields
      }
    }
  }
`

export const CART_UPDATE_FREQUENCY = gql`
  ${FRAGMENT_FULL_CART}
  mutation UpdateFrequencyInCart($input: CartUpdateFrequencyInput!) {
    cartUpdateFrequency(input: $input) {
      ...CoreFullCartFields
    }
  }
`

export const CART_UPDATE_QUANTITY = gql`
  ${FRAGMENT_FULL_CART}
  mutation UpdateQuantityInCart($input: CartUpdateQuantityInput!) {
    cartUpdateQuantity(input: $input) {
      ...CoreFullCartFields
    }
  }
`

export const MAYBE_COMPLETE_SEZZLE_ORDER = gql`
  mutation CompleteSezzleOrder {
    sezzleCompleteOrder {
      orderId
    }
  }
`

export const PAY_WITH_CARD = gql`
  mutation PayWithCard($input: PayWithNewCardInput!) {
    payWithCard(input: $input) {
      orderId
    }
  }
`
