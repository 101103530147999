import apolloClient from "@src/utilities/apollo-client"

import {
  CART_GET_FULL_CART,
  CART_GET_FULL_CART_FOR_PAYMENT,
} from "@src/operations/api/cart"

import type {
  GetFullCartQuery,
  GetCartAndSezzleUrlQuery,
} from "@src/entities/types/api"

export default async function getFullCart() {
  return apolloClient.query<GetFullCartQuery>({
    query: CART_GET_FULL_CART,
  })
}

export async function getFullCartWithSezzleCheckoutUrl() {
  return apolloClient.query<GetCartAndSezzleUrlQuery>({
    query: CART_GET_FULL_CART_FOR_PAYMENT,
  })
}
