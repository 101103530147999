import { useEffect, useState } from "react"

import useSiteScrolled from "@src/hooks/use-site-scrolled"

import Footer from "@src/components/Footer"
import SmartHeader from "@src/components/SmartHeader"
import OffCanvasNavigation from "@src/components/OffCanvasNavigation"

import type { SiteSettings } from "@src/entities/types/site-settings"
import CartFlyout from "@src/components/CartFlyout"

export interface GeneralLayoutProps {
  children?: React.ReactNode
  siteSettings: SiteSettings
  isWholesalePage?: boolean
}

export default function GeneralLayout({
  children,
  siteSettings,
  isWholesalePage = false,
}: GeneralLayoutProps) {
  const cartFlyoutBodyAttr: string = "data-cart-flyout-open"
  const isSiteScrolled = useSiteScrolled()

  const [offCanvasNavOpen, setOffCanvasNavOpen] = useState<boolean>(false)

  const toggleCartFlyout = () => {
    const isOpen: boolean =
      document.body.getAttribute(cartFlyoutBodyAttr) === "true"
    document.body.setAttribute(cartFlyoutBodyAttr, isOpen ? "false" : "true")
  }

  const toggleOcn: React.MouseEventHandler<HTMLButtonElement> = () => {
    setOffCanvasNavOpen((prevState) => !prevState)
  }

  const onResize = () => {
    setOffCanvasNavOpen(false)
  }

  useEffect(() => {
    if (offCanvasNavOpen) {
      document.body.classList.add("fixed")
    } else {
      document.body.classList.remove("fixed")
    }
    return () => {
      document.body.classList.remove("fixed")
    }
  }, [offCanvasNavOpen])

  useEffect(() => {
    if (!document.body.hasAttribute(cartFlyoutBodyAttr)) {
      document.body.setAttribute(cartFlyoutBodyAttr, "false")
    }
    window.addEventListener("resize", onResize)
    return () => {
      window.removeEventListener("resize", onResize)
    }
  }, [])

  return (
    <>
      <SmartHeader
        isScrolled={isSiteScrolled}
        isWholesalePage={isWholesalePage}
        onMenuButtonClick={toggleOcn}
        onToggleCartFlyout={toggleCartFlyout}
        preheaderMessage={siteSettings.alertBarHtml}
        preheaderNavigationMenu={siteSettings.preheaderNavigationMenu}
        primaryNavigationMenu={siteSettings.primaryNavigationMenu}
        secondaryNavigationMenu={siteSettings.secondaryNavigationMenu}
      />
      <OffCanvasNavigation
        isOpen={offCanvasNavOpen}
        navMenu={siteSettings.primaryNavigationMenuMobile}
      />
      <main>{children}</main>
      <Footer
        bottomImageUrl={siteSettings.footerImageUrl}
        disclosureText={siteSettings.fdaDisclosure}
        navigationMenu={siteSettings.footerNavigationMenu}
        socialLinks={siteSettings.socialLinks}
      />
      <CartFlyout onToggleCartFlyout={toggleCartFlyout} />
    </>
  )
}
