import apolloClient from "@src/utilities/apollo-client"

import { CART_REMOVE_ITEM } from "@src/operations/api/cart"

import type {
  RemoveFromCartMutation,
  RemoveFromCartMutationVariables,
} from "@src/entities/types/api"

export default async function updateFrequencyInCart(
  input: RemoveFromCartMutationVariables["input"]
) {
  return apolloClient.mutate<
    RemoveFromCartMutation,
    RemoveFromCartMutationVariables
  >({
    mutation: CART_REMOVE_ITEM,
    variables: { input },
  })
}
