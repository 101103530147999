"use client"

import { type MouseEventHandler, useEffect, useState } from "react"
import {
  BsArrowRepeat,
  BsBag,
  BsCheckCircle,
  BsExclamationTriangle,
  BsX,
} from "react-icons/bs"
import _map from "lodash/map"
import _reduce from "lodash/reduce"

import CartFlyoutLineItem from "@src/components/CartFlyoutLineItem"

import { useAppDispatch, useAppSelector } from "@src/hooks/use-redux"

import { getCartCount, setCartCount } from "@src/redux/cart.slice"
import { getUser } from "@src/redux/user.slice"

import { formatCurrency } from "@src/utilities/format-numbers"

import addDiscountCode from "@src/workers/api/add-discount-code"
import getCart from "@src/workers/api/get-cart"
import removeFromCart from "@src/workers/api/remove-from-cart"
import updateQuantityInCart from "@src/workers/api/update-quantity-in-cart"

import type { GetFullCartQuery } from "@src/entities/types/api"
import classNames from "@src/utilities/class-names"

export interface CartFlyoutProps {
  onToggleCartFlyout: MouseEventHandler<HTMLButtonElement>
}

export default function CartFlyout({ onToggleCartFlyout }: CartFlyoutProps) {
  const dispatch = useAppDispatch()
  const [cart, setCart] = useState<
    GetFullCartQuery["cart"] | null | undefined
  >()
  const cartItemsCount = useAppSelector(getCartCount)
  const [cartIsLoading, setCartIsLoading] = useState<boolean>(true)
  const [cartIsUpdating, setCartIsUpdating] = useState<boolean>(false)
  const [discountCode, setDiscountCode] = useState<string>("")
  const minimumOrderInCents = 30000
  const user = useAppSelector(getUser)

  function onChangeItemQuantity(sku: string, quantity: number) {
    setCartIsUpdating(true)
    updateQuantityInCart({
      frequencyInWeeks: null,
      quantity,
      sku,
    }).then(({ data, errors }) => {
      if (errors) {
        _map(errors, (error) => {
          console.error(error)
        })
        return
      }
      dispatch(
        setCartCount(
          _reduce(
            data?.cartUpdateQuantity.lineItems ?? [],
            (sum, item) => sum + item.quantity,
            0
          )
        )
      )
      setCart(data?.cartUpdateQuantity)
      setCartIsUpdating(false)
    })
  }

  function onRemoveItem(sku: string) {
    setCartIsUpdating(true)
    removeFromCart({
      frequencyInWeeks: null,
      sku,
    }).then(({ data, errors }) => {
      if (errors) {
        _map(errors, (error) => {
          console.error(error)
        })
        return
      }
      dispatch(
        setCartCount(
          _reduce(
            data?.cartRemoveItem.lineItems ?? [],
            (sum, item) => sum + item.quantity,
            0
          )
        )
      )
      setCart(data?.cartRemoveItem)
      setCartIsUpdating(false)
    })
  }

  async function onSubmitDiscountCode(): Promise<void> {
    const code = discountCode.trim().toUpperCase()
    if (code.length === 0) {
      return
    }
    setCartIsUpdating(true)
    const { data, errors } = await addDiscountCode(code)
    setCartIsUpdating(false)
    if (errors) {
      const errorMessage = _map(errors, (error) => error.message).join(",")
      console.log(errorMessage)
      return
    }
    setCart(data?.addDiscount)
    setDiscountCode("")
  }

  function refreshCart() {
    if (!cart) {
      setCartIsLoading(true)
    } else {
      setCartIsUpdating(true)
    }
    getCart().then(({ data, errors }) => {
      if (errors) {
        _map(errors, (error) => {
          console.log(error)
        })
        console.log(errors)
        return
      }
      setCart(data.cart)
      setCartIsLoading(false)
      setCartIsUpdating(false)
    })
  }

  useEffect(() => {
    if (!user.authStateKnown || cartItemsCount === -1) {
      return
    }
    refreshCart()
  }, [cartItemsCount, user])

  return (
    <>
      <button
        className="bg-black fixed bottom-0 left-0 right-0 opacity-0 top-0 transition-all z-[99]"
        data-component="cart-flyout-overlay"
        onClick={onToggleCartFlyout}
      />
      <div
        className="bg-white fixed shadow h-screen max-w-xs flex flex-col h-screen justify-between right-0 transition-transform top-0 w-screen z-[100]"
        data-component="cart-flyout"
      >
        <div className="flex justify-between items-center px-4 py-2">
          <div className="flex items-center">
            <BsBag className="h-4 w-4" />
            <span className="font-browser font-bold pl-1 uppercase">
              My Basket
            </span>
          </div>
          <button onClick={onToggleCartFlyout}>
            <BsX className="h-8 w-8" />
          </button>
        </div>
        {cart?.discountCode === null && (
          <div className="px-4 mb-4">
            <div className="font-browser pb-2 text-sm text-gray-500">
              Enter dealer code from welcome email
            </div>
            <div className="bg-rose-100 p-2">
              <div className="bg-white flex border border-slate-200 rounded">
                <input
                  className="grow font-browser rounded-l text-sm p-2 w-full"
                  onChange={(e) => setDiscountCode(e.target.value)}
                  placeholder="Enter dealer code"
                  value={discountCode}
                />
                <button
                  className="bg-white border-l pt-2 pb-1 px-4 rounded-r text-xs hover:bg-orange hover:text-white transition-colors disabled:bg-slate-200 disabled:text-slate-500 disabled:pointer-events-none"
                  disabled={cartIsLoading || cartIsUpdating}
                  onClick={() => {
                    onSubmitDiscountCode()
                  }}
                >
                  Apply
                </button>
              </div>
            </div>
          </div>
        )}
        <div className="grow overflow-x-scroll">
          <div className="space-y-8 px-4">
            {_map(cart?.lineItems, (item, idx) => (
              <CartFlyoutLineItem
                key={`cart-flyout-item-${idx}`}
                item={item}
                isUpdating={cartIsLoading || cartIsUpdating}
                onChangeItemQuantity={onChangeItemQuantity}
                onRemoveItem={onRemoveItem}
              />
            ))}
          </div>
        </div>
        <div className="px-4 py-2">
          <div className="border-t pt-2">
            <div
              className={classNames(
                "flex items-center mb-2 text-sm space-x-1",
                !cart?.isWholeSale || cart?.discountCode === null
                  ? "bg-rose-400 font-browser p-1"
                  : ""
              )}
            >
              <BsCheckCircle
                className={classNames(
                  "h-4 w-4 text-green-500",
                  cart?.isWholeSale && cart.discountCode !== null
                    ? "inline-block"
                    : "hidden"
                )}
              />
              <BsExclamationTriangle
                className={classNames(
                  "h-4 w-4 text-black",
                  cart?.discountCode === null ? "inline-block" : "hidden"
                )}
              />
              <span>
                {cart?.isWholeSale && cart.discountCode !== null
                  ? "Dealer code applies"
                  : "Dealer code not applied"}
              </span>
            </div>
            <div className="flex justify-between items-center">
              <span className="font-browser font-bold uppercase">
                Bundle Total:
              </span>
              {cartIsLoading || cartIsUpdating ? (
                <BsArrowRepeat className="h-4 w-4 animate-spin" />
              ) : (
                <span className="font-browser font-bold">
                  {formatCurrency(cart?.totals.total || 0)}
                </span>
              )}
            </div>
          </div>
          <div className="mb-4 text-gray-500 text-xs">Minimum order: $300</div>
          <a
            className={classNames(
              "bg-black-600 block font-medium px-8 pb-2 pt-3 text-center text-xs text-white uppercase w-full md:w-auto hover:bg-orange-600 transition-colors uppercase",
              (cart?.totals.total || 0) < minimumOrderInCents
                ? "opacity-50 pointer-events-none"
                : ""
            )}
            href="/checkout"
          >
            Checkout Now
          </a>
        </div>
      </div>
    </>
  )
}
