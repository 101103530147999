import apolloClient from "@src/utilities/apollo-client"

import { CART_UPDATE_QUANTITY } from "@src/operations/api/cart"

import type {
  UpdateQuantityInCartMutation,
  UpdateQuantityInCartMutationVariables,
} from "@src/entities/types/api"

export default async function updateQuantityInCart(
  input: UpdateQuantityInCartMutationVariables["input"]
) {
  return apolloClient.mutate<
    UpdateQuantityInCartMutation,
    UpdateQuantityInCartMutationVariables
  >({
    mutation: CART_UPDATE_QUANTITY,
    variables: { input },
  })
}
